import GlobalStyles from './GlobalStyles';
import Page from './Page';
import Snack from './Snack';

const Common = {
  GlobalStyles,
  Page,
  Snack,
};

export default Common;
